@import './const/var.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

body {
  margin: 0;
  background: $manBgColor;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
  color: $textColor;
}

input {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
  color: $textColor;
}

.space {
  padding: 10px;
}

.pin {
  width: 0;
  height: 0;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.inputErrorMessage {
  margin-top: 2px;
  margin-bottom: 2px;
  color: #f71313;
}

.borderErrorColor {
  border-color: #f71313 !important;
}

.borderErrorColor > div {
  border-color: #f71313 !important;
}

.visibilityNone {
  display: none;
}

.ant-upload-list-item-name {
  cursor: pointer;
}