.topBar {
  box-shadow: 0px 0px 5px -2px #333;

  .input {
    max-width: 350px;
  }

  .unborderButton {
    border: none;
    margin-left: 30px;
  }

  .logoutBtn {
    float: right;
    margin-top: 15px;
  }
}
