$white: #ffffff;
$manBgColor: #f9f9f9;
$mainBlue: #266396;
$mainDarkerBlue: #2794be;
$textColor: #616161;
$lightTextColor: #828282;
$darkTextColor: #333333;

$animationTime: .3s;

$lightOnDarkTiny: #133747;
$lightOnDarkStrong: #c3fafd;