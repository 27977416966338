@import './../const/var.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .logoHolder {
    margin-bottom: 15px;

    img {
      max-width: 200px;
      width: 100%;
    }
  }

  .formHolder {
    border: solid 2px #e6e6e6;
    border-radius: 7px;
    overflow: hidden;

    .header {
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      background: #f9f9f9;
      padding: 15px 0 20px 0;
      border-bottom: solid 2px #e6e6e6;
    }

    .contentFormHolder {
      background: #ffffff;
      padding: 50px 20px;

      .inputHolder {
        margin-bottom: 15px;

        .inputHeader {
          text-transform: uppercase;
          font-size: 12px;

          span {
            color: #cc0707;
            margin-left: 3px;
          }

          .remindPassword {
            text-transform: capitalize;
            color: $mainBlue;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .flexHeader {
          display: flex;
          justify-content: space-between;
        }

        .loginInput {
          min-width: 290px;
          padding: 10px;
          margin-top: 5px;
          border-radius: 7px;
          border: solid 2px #e6e6e6;

          input {
            font-size: 15px;
          }
        }
      }

      .button {
        background: $mainBlue;
        padding: 10px;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        border-radius: 7px;
        transition: $animationTime;

        &:hover {
          cursor: pointer;
          background: $mainDarkerBlue;
        }
      }

      .checkboxContainer {
        display: block;
        padding-left: 30px;
        padding-top: 4px;
        margin-top: 10px;
        position: relative;
        font-size: 13px;
        user-select: none;
        cursor: pointer;

        &:hover {
          input {
            background-color: #555;
          }

          .chckboxMask {
            background-color: #555;
          }
        }

        input {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
          cursor: pointer;
        }

        input:checked ~ .chckboxMask {
          background-color: #333;
        }

        input:checked ~ .chckboxMask::after {
          display: block;
        }

        .chckboxMask {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background: #ddd;
          transition: $animationTime;
          border-radius: 7px;

          &::after {
            content: "";
            position: absolute;
            display: none;
            left: 10px;
            top: 7px;
            width: 5px;
            height: 10px;
            border: solid #ffffff;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
