@import './../const/var.scss';

.dashboardContainer {
  min-height: 100vh;

  .logoBar {
    background: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 60px;
    overflow: hidden;

    .logo {
      max-width: 100px;
      width: 100%;
    }

    .trigger {
      margin-right: 20px;
    }
  }

  .singleLogoBar {
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 100%;
      position: fixed;
      animation: fade .3s forwards;
    }
  }

  .menuSlide {
    background-color: $mainBlue;
    min-height: 100vh;
    box-sizing: border-box;
    flex: auto !important;
  }

  .contentWrapper {
    padding: 30px;

    .content {
      margin-top: 20px;
    }
  }
}

@keyframes fade {
  0% {
    max-width: 100px;
    opacity: 1;
  }
  100% {
    max-width: 0px;
    opacity: 0;
  }
}