@import './../../const/var.scss';

.ant-menu {
  background: $mainBlue !important;
}

.ant-menu-item {
  color: $lightOnDarkStrong !important;

  a {
    color: $lightOnDarkStrong !important;
  }
}

.ant-menu-item-active {
  background: $mainDarkerBlue !important;
  color: $lightOnDarkTiny !important;

  a {
    color: $lightOnDarkTiny !important;
  }
}

.ant-menu-item-selected {
  background: $mainDarkerBlue !important;
  color: $lightOnDarkStrong !important;

  a {
    color: $lightOnDarkStrong !important;
  }
}

.ant-menu-inline {
  border-right: none !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  color: white !important;
  font-weight: bold;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: white !important;
}

.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  color: white !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none !important;
}