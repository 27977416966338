@import './../const/var.scss';

.container {
  .topBar {
    box-shadow: 0px 0px 5px -2px #333;
  }

  .logoContainer {
    background: $white;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      max-width:120px;
      max-height:90%;
      width: auto;
      height: auto;
    }



  }

  .textContainer {
    margin: 30px;
    background: $white;
    padding: 20px;
  }
}
